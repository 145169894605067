import { IO } from "functional/lib/IO"
import { FlexCol, FlexRow } from "../../../components/Flexbox"
import { RState, useRState } from "../../../functional/react/RState"
import { BuyRequest, BuyRequestType } from "../../../model/buyRequest/BuyRequest"
import { CallError, ConectoDialog, ExtraActions, ShowJsonAction, SubmitButton } from "../detail-components"
import { Unit } from "functional/lib/core"
import { Maybe } from "functional/lib/Maybe"
import { defaultFeeConditions, defaultFeeMessage } from "../../../config"
import { Button } from "react-bootstrap"
import { useAppAsynchronism } from "../../../context/AppCall"
import { Id } from "../../../model/Model"
import { buyRequestUpdate } from "../../../client/buy-request"
import { FeeConditions } from "../../../model/buyRequest/BuyRequestBase"
import { FeeConditionsDraft, FeeConditionsEditor } from "../company/actions"
import { StringEditor, StringEditorMultiline } from "../../../components/editors/string"

export const BuyRequestActions = (
  props: {
    data: BuyRequest
  }
) => {

  return <FlexRow>

    <ExtraActions
      actions={[
        <EditFeeAction
          data={props.data}
        />,
        <EditFeeMessageAction
          data={props.data}
        />,
        <ShowJsonAction
          data={props.data}
        />
      ]}
    />

  </FlexRow>
}

const EditFeeAction = (
  props: {
    data: BuyRequest
  }
) => {

  const open = useRState(() => false)

  return <FlexCol>
    <FlexRow
      onClick={open.apply(() => true)}
      stopPropagation={false}
    > 
      Modificar Comisión
    </FlexRow>

    <ConectoDialog
      open={open}
      title="Modificar Comisión"
      style={{
        width: 400
      }}
    >
      <EditFeeForm
        data={props.data}
        onCancel={open.apply(() => false)}
      />
    </ConectoDialog>

  </FlexCol>
}


const EditFeeMessageAction = (
  props: {
    data: BuyRequest
  }
) => {

  const open = useRState(() => false)

  return <FlexCol>
    <FlexRow
      onClick={open.apply(() => true)}
      stopPropagation={false}
    > 
      Modificar Mensaje de Comisión
    </FlexRow>

    <ConectoDialog
      open={open}
      title="Modificar Mensaje de Comisión"
      style={{
        width: 800
      }}
    >
      <EditFeeMessageForm
        data={props.data}
        onCancel={open.apply(() => false)}
      />
    </ConectoDialog>

  </FlexCol>
}




const EditFeeForm = (
  props: {
    data: BuyRequest
    onCancel: IO<Unit>
  }
) => {

  const feeConditions = props.data.feeConditions ?? defaultFeeConditions

  const state = useRState<FeeConditionsDraft>(
    () => ({
      max: feeConditions.max,
      min: feeConditions.min,
      percent: feeConditions.factor * 100
    })
  )

  const result = 
    Maybe.do<FeeConditions>(_ => ({
      factor: _(state.value.percent) / 100,
      min: _(state.value.min),
      max: _(state.value.max)
    }))

  const call = useAppAsynchronism(
    (args: {
      buyRequest: {
        type: BuyRequestType
        id: Id
      }
      data: FeeConditions
    }) =>
      buyRequestUpdate({
        buyRequest: args.buyRequest,
        data: {
          feeConditions: args.data
        }
      })
  )

  return <FlexCol
    style={{ 
      width: "100%",
      padding: 10
    }}
    alignItems="stretch"
    gap={16}
  >

    <FeeConditionsEditor
      state={state}
    />

    <FlexRow
      justifyContent="flex-end"
      gap={8}
    >

      <Button
        variant="light"
        onClick={props.onCancel}
      >
        Cancelar
      </Button>

      <SubmitButton
        label="Modificar"
        call={call}
        value={
          Maybe.do(_ => ({
            buyRequest: { type: props.data.type, id: props.data.id ?? "" },
            data: _(result)
          }))
        }
      />

    </FlexRow>

    <CallError
      call={call}
    />

  </FlexCol>
}



const EditFeeMessageForm = (
  props: {
    data: BuyRequest
    onCancel: IO<Unit>
  }
) => {

  const feeConditions = props.data.feeMessage ?? defaultFeeMessage

  const state = useRState(
    () => ({
      message: feeConditions.message,
      detail: feeConditions.detail,
    })
  )

  const call = useAppAsynchronism(
    (args: {
      buyRequest: {
        type: BuyRequestType
        id: Id
      }
      data: {
        message: string
        detail: string
      }
    }) =>
      buyRequestUpdate({
        buyRequest: args.buyRequest,
        data: {
          feeMessage: args.data
        }
      })
  )

  return <FlexCol
    style={{ 
      width: "100%",
      padding: 10
    }}
    alignItems="stretch"
    gap={16}
  >

    <FeeMessageEditor
      state={state}
    />

    <FlexRow
      justifyContent="flex-end"
      gap={8}
    >

      <Button
        variant="light"
        onClick={props.onCancel}
      >
        Cancelar
      </Button>

      <SubmitButton
        label="Modificar"
        call={call}
        value={{
          buyRequest: { type: props.data.type, id: props.data.id ?? "" },
          data: state.value
        }}
      />

    </FlexRow>

    <CallError
      call={call}
    />

  </FlexCol>
}


const FeeMessageEditor = (
  props: {
    state: RState<{
      message: string
      detail: string
    }>
  }
) => {

  const fields = RState.destructureAll(props.state)

  return <FlexCol
    gap={8}
    alignItems="stretch"
  >

    <FlexCol
      alignItems="flex-start"
      gap={2}
    >
      <b>Mensaje</b>
      <StringEditorMultiline
        rows={2}
        state={fields.message}
      />
    </FlexCol>

    <FlexCol
      alignItems="flex-start"
      gap={2}
    >
      <b>Detalle</b>
      <StringEditorMultiline
        rows={8}
        state={fields.detail}
      />
    </FlexCol>

  </FlexCol>
}
