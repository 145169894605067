import { FeeConditions } from "./model/buyRequest/BuyRequestBase"


export const defaultFeeConditions: FeeConditions = {
    factor: 0.5 / 100,
    min: 0,
    max: 15_000
}

export const defaultFeeMessage = {
    message: "Comisión a pagar a Conecto Energía por el Vendedor Adjudicado: ** 0,5 % del valor total del PPA **",
    detail: [
        "Mediante el consentimiento a estos Términos y Condiciones Particulares y ",
        "conjuntamente con el envío de su oferta, la Empresa interesada que resulte ",
        "adjudicada y haya celebrado un contrato de abastecimiento con el Comprador, ",
        "acepta abonar al Sitio una comisión equivalente al 0,5 % del valor total del contrato de abastecimiento ",
        "en concepto de uso del Sitio.",
        "Conecto Energía facturará dicha comisión la momento ",
        "en que el Comprador y Vendedor suscriban el contrato de abastecimiento, ",
        "en pesos al tipo de cambio vendedor Banco Nación correspondiente al día ",
        "anterior a la fecha de emisión de la factura. Dicha factura deberá ser ",
        "abonada dentro de los 30 días hábiles de recibida la misma."
    ].join("")
  }

export const productionConfig = {
    apiKey: "AIzaSyBlxFgd8mwvwCjn2OPEh0_mOfS83HUFI50",
    authDomain: "conecto-energia.firebaseapp.com",
    databaseURL: "https://conecto-energia.firebaseio.com",
    projectId: "conecto-energia",
    storageBucket: "conecto-energia.appspot.com",
    messagingSenderId: "696631049214",
    appId: "1:696631049214:web:a0d84f247d08fcba"
}

export const developmentConfig = {
    apiKey: "AIzaSyBSAhmymccr8Abh3jofJ1Y2rXOwdLdsGTw",
    authDomain: "directo-4c9a3.firebaseapp.com",
    databaseURL: "https://directo-4c9a3.firebaseio.com",
    projectId: "directo-4c9a3",
    storageBucket: "directo-4c9a3.appspot.com",
    messagingSenderId: "762750559170",
    appId: "1:762750559170:web:d3669b72fe6a79cfc9ec5b"
}


export const productionDatabases = {
    users: 'users',
    gasBuyRequests: 'gasBuyRequests',
    trainingGasBuyRequests: 'trainingGasBuyRequests',
    electricityBuyRequests : 'electricityBuyRequests',
    drafts: 'drafts',
    trainingElectricityBuyRequests : 'trainingElectricityBuyRequests',
    companies: 'companies',
    supplyPoints: 'supplyPoints',
    comments: 'comments',
    mailQueue: 'mailQueue',
    mail: 'mail',
    termsAndConditions: 'termsAndConditions'
}

export const developmentDatabases = {
    users:'users',
    gasBuyRequests: 'gasBuyRequests',
    trainingGasBuyRequests: 'trainingGasBuyRequests',
    drafts: 'drafts',
    electricityBuyRequests : 'electricityBuyRequests',
    trainingElectricityBuyRequests : 'trainingElectricityBuyRequests',
    companies: 'companies',
    supplyPoints: 'supplyPoints',
    mailQueue: 'mailQueue',
    termsAndConditions: 'termsAndConditions'
}

export const firebaseConfig = productionConfig
export const databases = productionDatabases
