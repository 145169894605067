import { IsNumber } from "@sinclair/typebox/value/guard"
import { Maybe } from "functional/lib/Maybe"
import { match, matchEnum, matchPartial } from "functional/lib/match"
import { Button, ButtonGroup } from "react-bootstrap"
import { useParams } from "react-router"
import { FlexCol, FlexRow } from "../../../components/Flexbox"
import { useRState } from "../../../functional/react/RState"
import { useBid, useBuyRequest, useEntityList, useProcessList, useSupplyPoint } from "../../../hooks/ConectoHooks"
import { BuyRequest, BuyRequestType } from "../../../model/buyRequest/BuyRequest"
import { ElectricityBuyRequest } from "../../../model/buyRequest/ElectricityBuyRequest"
import { GasBuyRequest, GasBuyTypeSpot } from "../../../model/buyRequest/GasBuyRequest"
import { CommentIcon, ConectoCell, ConectoGrid, DetailSection, Field, FieldBid, FieldBids, FieldBoolean, FieldCompanies, FieldCompany, FieldDate, FieldFile, FieldFiles, FieldNumber, FieldText, FieldUser, ProcessIcon } from "../detail-components"
import { ButtonTabs, IdView, PageLoading, ProductView, useScrollToTop } from "../components"
import { ProcessTable, processColumns } from "../process/table"
import { BuyRequestStatusView } from "./components"
import BuyRequestIcon from "@material-ui/icons/ShoppingCartRounded"
import { List } from "functional/lib/List"
import { none } from "functional/lib/core"
import { defaultFeeConditions, defaultFeeMessage } from "../../../config"
import { BuyRequestActions } from "./actions"
import { CommentTable, commentColumns } from "../comment/table"
import { useBuyRequestCollection } from "../../../context/App"
import { Comment } from "../../../model/Model"
import { computeFee, computeContractValue, computeContractValueAndFee } from "../../../utils/fee"



export const BuyRequestDetailPage = () => {

  const params = useParams()

  const type = params.type as BuyRequestType
  const id = params.id as string

  const [data, loading, error] = useBuyRequest(id, type)

  useScrollToTop()

  return Maybe.map(data)(it => 
    <BuyRequestDetail
      data={it}
    />
  ) ?? <PageLoading/>
}

const BuyRequestDetail = (
  props: {
    data: BuyRequest
  }
) => {

  const data = props.data

  return <FlexCol
    alignItems="stretch"
    justifyContent="flex-start"
    padding={16}
    style={{
      background: "#f5f5f5",
      minHeight: "100vh"
    }}
    gap={8}
  >
    
    <FlexCol
      alignItems="stretch"
      padding={16}
      style={{
        border: "1px solid #ddd",
        borderRadius: 4,
        background: "white",
        boxShadow: "0 0 8px #ddd"
      }}
    >

      <Header
        data={data}
      />

      <hr/>

      <DataSection
        data={data}
      />

      <hr/>

      <StatusSection
        data={data}
      />

      <hr/>

      {
        match(props.data)({
          gas: it => <GasSection data={it} />,
          electricity: it => <ElectricitySection data={it}/>,
        })
      }

      <hr/>

      <BuyTypeSection
        data={data}
      />

      <hr/>

      <SupplyPointSection
        data={data}
      />

      <hr/>

      <FeeSection
        data={data}
      />

    </FlexCol>

    <Relations
      data={data}
    />

  </FlexCol>
}


const Header = (
  props: {
    data: BuyRequest
  }
) => {

  return <FlexCol
    alignItems="stretch"
  >

    <FlexRow
      justifyContent="space-between"
    >

      <FlexRow
        gap={6}
        alignItems="center"
      >
        <BuyRequestIcon
          style={{
            fontSize: 48,
            color: "#888888"
          }}
        />
        <h1>
          Pedido de Compra {props.data.id ?? "-"}
        </h1>
      </FlexRow>

      <BuyRequestActions
        data={props.data}
      />

    </FlexRow>

    

    <IdView
      style={{
        width: "max-content",
      }}
      id={props.data.id}
    />

  </FlexCol>
}


const DataSection = (
  props: {
    data: BuyRequest
  }
) => {

  const data = props.data

  return <DetailSection
    title="Datos"
  >
    <ConectoGrid
      defaults={{
        xs: 2
      }}
      cells={[
        <FieldCompany
          label="Empresa Compradora"
          companyId={data.buyerCompanyId}
        />,
        <FieldText
          label="Tipo"
          value={matchEnum(data.type)({
            gas: "🔥 Gas",
            electricity: "⚡️ Electricidad"
          })}
        />,
        <FieldText
          label="Producto"
          value={matchEnum(data.product ?? "_")({
            wellhead: "Boca de Pozo",
            cityGate: "City Gate",
            electricity: "Electricidad",
            mater: "MATER",
            plant: "En Planta",
            _: "-"
          })}
        />,
        <FieldText
          label="Tipo de Cambio"
          value={data.exchangeRate}
        />,
        <FieldUser
          label="Creado por"
          userId={data.placerId}
        />,
        <FieldDate
          label="Fecha de creación"
          value={data.creationDate}
        />,
        [{ xs: 6 }, <FieldCompanies
          label="Empresas Invitadas"
          weigth={2}
          companyList={data.currentGuestIdList ?? []}
        />],
        [{ xs: 6 }, <FieldCompanies
          label="Empresas alguna vez Invitadas"
          weigth={2}
          companyList={data.onceInvited ?? []}
        />],
      ]}
    />
  </DetailSection>
}


const GasSection = (
  props: {
    data: GasBuyRequest
  }
) => {

  return <DetailSection
    title="Gas"
  >

    <ConectoGrid
      defaults={{
        xs: 2
      }}
      cells={[
        <FieldDate
          label="Fecha de inicio de Suministro"
          value={props.data.supplyStartDate}
        />,
        <FieldDate
          label="Fecha de fin de Suministro"
          value={props.data.supplyEndDate}
        />,
        <FieldNumber
          label="Calidad"
          value={props.data.quality}
        />,
        <FieldNumber
          label="Plazo de Pago de Referencia"
          value={props.data.referencePayTermDays}
          units="días"
        />,
        <FieldText
          label="Tipo de mix"
          value={
            matchEnum(props.data.mix?.type ?? "_")({
              free: "Libre",
              distributor: "Distribuidor",
              _: "-"
            })}
        />,
        <FieldText
            label="Tipo de Suministro"
            value={
              matchEnum(props.data.supplyType)({
                firm: "Firme",
                interruptible: "Interrumpible"
              })
            }
        />,
        <FieldNumber
          label="Deliver Or Pay"
          value={props.data.deliverOrPay}
        />,
        <FieldNumber
          label="Take Or Pay"
          value={props.data.takeOrPay}
        />,
        <FieldBoolean
          label="Exclusividad"
          value={props.data.exclusivity}
        />,
        <FieldBoolean
          label="Acepta menos volumen"
          value={props.data.acceptsLessVolume}
        />,
        <FieldNumber
          label="Cantidad Máxima Diaría (CMD)"
          value={props.data.maximumDailyAmount}
          decimals={2}
        />,
        <FieldText
          label="Tipo de Producto"
          value={props.data.productType}
        />,
        <FieldBoolean
          label="Compra Conjunta"
          value={props.data.jointPurchase}
        />,
        <FieldNumber
          label="Orden"
          value={props.data.order}
        />,
        [{ xs: 8 }, <FieldFiles
          label="Documentación Adicional"
          files={props.data.fileList}
        />]
      ]}
    />

  </DetailSection>
}


const ElectricitySection = (
  props: {
    data: ElectricityBuyRequest
  }
) => {

  return <DetailSection
    title="Electricidad"
  >

    <ConectoGrid
      defaults={{
        xs: 4
      }}
      cells={[
        <FieldText
          label="Cantidad total"
          value={props.data.totalAmount?.toString()}
        />,
        <FieldFile
          label="Template de Oferta"
          file={props.data.fileList?.offerTemplate}
        />,
        <FieldFiles
          label="Documentos"
          files={props.data.fileList?.documents}
        />,
        <FieldFile
          label="Terminos y Condiciones"
          file={props.data.fileList?.termsAndConditions}
        />,
        <FieldFiles
          label="Info Complementaria"
          files={props.data.fileList?.complimentaryInfo}
        />,
        <FieldFiles
          label="Anexo"
          files={props.data.fileList?.anex}
        />,
      ]}
    />

  </DetailSection>
}


const StatusSection = (
  props: {
    data: BuyRequest
  }
) => {

  const status = props.data.status

  const [processList, , ] = useProcessList(
    props.data.id,
    props.data.type
  )

  const activeProcessId = processList?.at(-1)?.id ?? "-"

  const [bid, , ] = useBid(
    props.data.type,
    props.data.id,
    activeProcessId,
    status.type === "assigned" ? status.bidId : "-"
  )

  const { contractValue, fee } = computeContractValueAndFee(props.data, bid?.offer)

  return <DetailSection
    title="Estado"
  >

    <ConectoGrid
      defaults={{
        xs: 2
      }}
      cells={[
        <Field
          label="Estado"
        >
          <BuyRequestStatusView
            status={status}
          />
        </Field>,
        ...(matchPartial(status ?? { type: "_" })<List<ConectoCell>>({

          assigned: status =>
            [
              <FieldBid
                label="Oferta Ganadora"
                buyRequest={{
                  type: props.data.type,
                  id: props.data.id ?? ""
                }}
                processId={activeProcessId}
                bidId={status.bidId}
              />,
              <FieldCompany
                label="Asignado a"
                companyId={status.assignedCompanyId}
              />,
              <FieldNumber
                label="Valor de Contrato"
                value={contractValue}
                decimals={2}
                units="USD"
              />,
              <FieldNumber
                label="Comisión Conecto"
                value={fee}
                decimals={2}
                units="USD"
              />,
              [{ xs: 6 }, <FieldText
                label="Comentario"
                weight={3}
                value={status.comment}
              />]
            ],

          // preAssigned: status =>
          // [
          //   <FieldCompany
          //     label="Asignado a"
          //     companyId={status.assignedCompanyId}
          //   />,
          //   <FieldBids
          //     label="Ofertas"
          //     buyRequest={{
          //       type: props.data.type,
          //       id: props.data.id ?? ""
          //     }}
          //     processId={activeProcessId}
          //     bidIds={status.bidIdList}
          //   />,
          //   <FieldCompanies
          //     label="Pre-Asignado a"
          //     companyList={status.preAssignedCompanyIdList}
          //   />,
          //   [{ xs: 4 }, <FieldText
          //     label="Comentario"
          //     weight={2}
          //     value={status.comment}
          //   />] 
          // ],

          deserted: status =>
            [
              [{ xs: 10 }, <FieldText
                label="Comentario"
                weight={4}
                value={status.comment}
              />]
            ],

        }) ?? [])
      ]}
    />

  </DetailSection>
}

const BuyTypeSection = (
  props: {
    data: BuyRequest
  }
) => {

  const data = props.data

  const buyType = data.buyType ?? { type: "" }

  return matchPartial(buyType)({
    spot: it => <SpotSection
      data={it}
    />

  }) ??
  <DetailSection
    title="Compra"
  >
    <ConectoGrid
      defaults={{ xs: 2 }}
      cells={[
        <FieldText
          label="Tipo de Compra"
          value={
            matchEnum(buyType.type)({
              annual: "Anual",
              monthly: "Mensual",
              spot: "Spot",
              mater: "MATER",
              onsite: "Onsite",
              surplus: "Surplus",
              thermal: "Térmica",
              plus: "Plus"
            })
          }
        />
      ]}
    />
  </DetailSection>
}

const SpotSection = (
  props: {
    data: GasBuyTypeSpot
  }
) => {

  return <DetailSection
    title="Compra"
  >
    <ConectoGrid
      defaults={{ xs: 2 }}
      cells={[
        <FieldText
          label="Tipo de Compra"
          value="Spot"
        />,
        <FieldText
          label="Cantidad"
          value={props.data.amount.toString()}
        />,
        <FieldText
          label="Plazo"
          value={`${props.data.termDays} días`}
        />
      ]}
    />

  </DetailSection>
}


const SupplyPointSection = (
  props: {
    data: BuyRequest
  }
) => {

  const [supplyPoint, loading, error] = useSupplyPoint(
    props.data.buyerCompanyId,
    props.data.supplyPointId
  )

  return <DetailSection
    title="Punto de Suministro"
  >

    <ConectoGrid
      defaults={{ xs: 2 }}
      cells={[
        <FieldText
          label="Id"
          value={supplyPoint?.id}
        />,
        <FieldText
          label="Nombre"
          value={supplyPoint?.name}
        />,
        <FieldText
          label="Nombre Corto"
          value={supplyPoint?.shortName}
        />,
        <Field
          label="Producto"
        >
          <ProductView product={supplyPoint?.product}/>
        </Field>,
        [{ xs: 4 }, <FieldText
          label="Dirección"
          value={
            Maybe.map(supplyPoint)(it =>
              [it.address.street, it.address.locality, it.address.province].join(", ") 
            )
          }
        />],
        <FieldText
          label="Categoría"
          value={supplyPoint?.category}
        />,
        <FieldBoolean
          label="Múltiple"
          value={supplyPoint?.multiple}
        />,
        <FieldText
          label="Tipo"
          value={supplyPoint?.type}
        />,
        <FieldText
          label="NEMO"
          value={supplyPoint?.nemo}
        />,
        
        <FieldText
          label="Distribuidor"
          value={supplyPoint?.distributor?.name}
        />,
        <FieldText
          label="Identificador del Distribuidor"
          value={supplyPoint?.distributor?.identifier}
        />,
      ]}
    />
  </DetailSection>
}


const FeeSection = (
  props: {
    data: BuyRequest
  }
) => {

  const data = props.data

  const feeConditions = props.data.feeConditions ?? defaultFeeConditions

  const feeMessage = props.data.feeMessage ?? defaultFeeMessage

  return <DetailSection
    title="Comisión"
  >

    <ConectoGrid
      defaults={{ xs: 2 }}
      cells={[
        <FieldText
          label="Pagada por"
          value={
            matchEnum(data.feeType ?? "_")({
              buyer: "Comprador",
              seller: "Vendedor",
              split: "Split",
              none: "Ninguno",
              _: "-"
            })}
        />,
        
        <FieldNumber
          label="Porcentaje"
          value={feeConditions.factor * 100}
          decimals={2}
          units="%"
        />,
        <FieldNumber
          label="Mínimo"
          value={feeConditions.min}
          units="USD"
        />,
        <FieldNumber
          label="Máximo"
          value={feeConditions.max}
          units="USD"
        />,

        ...(IsNumber(data.discount) ?
          [
            <FieldText
              label="Descuento"
              value={data.discount?.toString()}
            />
          ] :
          data.discount !== none ?
          [
            <FieldText
              label="Factor de Descuento"
              value={data.discount?.factor?.toString()}
            />,
            <FieldText
              label="Descuento Mínimo"
              value={data.discount?.min?.toString()}
            />,
            <FieldText
              label="Descuento Máximo"
              value={data.discount?.max?.toString()}
            />,
          ] :
          []
        ),
        ...(data.type === "electricity" ? [
            [
              {
                xs: 6
              },
              <FieldText
                label="Mensaje"
                markdown
                value={feeMessage.message}
              />
            ] as const,
            [
              {
                xs: 6
              },
              <FieldText
                label="Detalle"
                markdown
                value={feeMessage.detail}
              />
            ] as const,
          ] : []
        )]}
    />

    

  </DetailSection>
}



type Tab =
  | "process"
  | "comments"

const Relations = (
  props: {
    data: BuyRequest
  }
) => {

  const tab = useRState<Tab>(() => "process")

  return <FlexCol
    alignItems="stretch"
    gap={8}
  >

    <ButtonTabs
      style={{
        alignSelf: "flex-start"
      }}
      state={tab}
      options={[
        { 
          value: "process",
          icon: <ProcessIcon/>, 
          label: "Rondas" 
        },
        { 
          value: "comments", 
          icon: <CommentIcon/>,
          label: "Consultas" 
        },
      ]}
    />

    {
      matchEnum(tab.value)({
        process: <RequestProcessTable 
          data={props.data}
        />,
        comments: <RequestCommentTable
          data={props.data}
        />
      })
    }

  </FlexCol>
}

const RequestProcessTable = (
  props: {
    data: BuyRequest
  }
) => {

  const [list, loading, error] = useProcessList(
    props.data.id,
    props.data.type
  )

  return <ProcessTable
    title={`Total ${list?.length ?? "-"}`}
    buyRequestType={props.data.type}
    buyRequestId={props.data.id ?? "-"}
    isLoading={loading}
    data={list}
    columns={[
      processColumns.id,
      processColumns.creationDate,
      processColumns.type,
      processColumns.openDate,
      processColumns.closeDate,
      processColumns.round,
      processColumns.encrypted
    ]}
  />
}

const RequestCommentTable = (
  props: {
    data: BuyRequest
  }
) => {

  const collection = useBuyRequestCollection(props.data.type)

  const [list, loading, error] = useEntityList<Comment>(
    collection
      .doc(props.data.id ?? "-")
      .collection("comments")
      .orderBy("creationDate", "desc")
  )

  return <CommentTable
    title={`Total ${list?.length ?? "-"}`}
    isLoading={loading}
    data={list}
    columns={[
      commentColumns.id,
      commentColumns.date,
      commentColumns.user,
      commentColumns.answered,
      commentColumns.message,
      commentColumns.answer
    ]}
  />
}