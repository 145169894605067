import { useMemo } from "react"

const { compiler } = require('markdown-to-jsx')

export const MarkdownText = (
  props: {
    text: string
  }
) => {

  const result = useMemo(() => compiler(props.text), [props.text])

  return <>
    {result}
  </>
}

